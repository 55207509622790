import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import WaveBottom from "../components/Wave/WaveBottom";
import PostCard from "../components/PostCard/PostCard";

function PostsPage() {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(graphql`
    query PostPageQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "post" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              date
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            html
            excerpt
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO keywords={[`posts`, `updates`, `blog`]} title="Posts" />
      <div className="bg-white px-4 pt-4 pb-12 text-black">
        <div className="container mx-auto px-4">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Posts
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div className="flex flex-wrap justify-around">
            {edges.map(({ node: post }) => {
              return (
                <PostCard
                  title={post.frontmatter.title}
                  date={post.frontmatter.date}
                  image={post.frontmatter.featuredImage}
                  content={post.html}
                  key={post.frontmatter.title}
                />
              );
            })}
          </div>
        </div>
      </div>

      <WaveBottom color="#ffffff" />
    </Layout>
  );
}

export default PostsPage;
